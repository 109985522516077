import { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { BackendConnector } from '../lib/classes/BackendConnector';
import CenteredLayout from '../components/layout/CenteredLayout';
import { useSearchParams } from 'react-router-dom';


function ActivationPage() {
  const backend = new BackendConnector();
  const [searchParams] = useSearchParams();
  const [resultInfo, setResultInfo] = useState('');
backend.sendRequest(`/user/activate/${searchParams.get("token")}`, {
    method: 'GET',
    callback: (r) => {
        if(r.header.success) {
            setResultInfo('Aktivierung erfolgreich!')
        } else {
            setResultInfo('Fehler bei der Aktivierung... ' + r.header.message);
        }
    }
});

  return (
    <CenteredLayout>
        <h1 className="title is-1">Aktivierung</h1>
        <p>{resultInfo}</p>
    </CenteredLayout>
  );
}

export default ActivationPage;
