import { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { BackendConnector } from '../lib/classes/BackendConnector';
import CenteredLayout from '../components/layout/CenteredLayout';
import { useSearchParams } from 'react-router-dom';


function LoginPage() {
  const backend = new BackendConnector();
  const [searchParams] = useSearchParams();
  const [userName, setUserName] = useState('');
  const [passWord, setPassWord] = useState('');
  const [error, setError] = useState('');

  const infoMessage = searchParams.get("info");

  const onSubmit = () => {
    // ! Validate this stuff, lol!
    backend.sendRequest('/user/login', {
      method: 'POST',
      body: {
        userName: userName,
        passWord: passWord
      },
      callback: (r) => {
        if(r.header.success) {
          const token = r.token;
          console.log('Login Success! Token: ' + token);
          localStorage.setItem('userToken', token);
        } else {
          setError(r.header.message);
        }
      },
    });
  }

  return (
    <CenteredLayout>
        <h1 className="title is-1">Login</h1>
        {infoMessage ? <div className="notification is-info">{infoMessage}</div> : ''}
        <div className="field">
          <label className="label">Benutzername</label>
          <div className="control has-icons-left">
            <input 
              type="text" 
              className="input" 
              placeholder='Benutzername'
              onChange={(ev) => setUserName(ev.target.value)}
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
            </span>
          </div>
        </div>
        <div className="field">
          <label className="label">Passwort</label>
          <div className="control has-icons-left">
            <input 
              type="password" 
              className="input" 
              placeholder='Passwort'
              onChange={(ev) => setPassWord(ev.target.value)}
            />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
            </span>
          </div>
        </div>
        {error ? <div className="notification is-danger">{error}</div> : ''}
        <div className="buttons">
          <button className="button is-primary" onClick={onSubmit}>Login</button>
        </div>
    </CenteredLayout>
  );
}

export default LoginPage;
