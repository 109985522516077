import React from 'react';
import '../../styles.scss';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

interface EmptyLayoutProps {
    children?: React.ReactNode;
    pageTitle?: string;
}

function EmptyLayout(props: EmptyLayoutProps) {
    return (
        <div className='layout-empty'>
            <Helmet>
                <html className='has-navbar-fixed-top'/>
                <title>Jan Fath {props.pageTitle ? '| ' + props.pageTitle : ''}</title>
            </Helmet>
            <Header />
            {props.children}
            <Footer />
        </div>
    );
}

export default EmptyLayout;