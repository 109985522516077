import React from 'react';
import 'bulma/css/bulma.min.css';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SocialIconProps {
    link: string;
    icon: IconDefinition;
    text?: string;
}

function SocialIcon(props: SocialIconProps) {
    return (
        <a href={props.link}>
            <span className={props.text? 'icon-text' : 'icon'}>
                <span className="icon">
                    <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
                </span>
                {props.text ? <span>{props.text}</span> : ''}
            </span>
        </a>
    );
}

export default SocialIcon;