import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import { faEnvelope, faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { BackendConnector } from '../lib/classes/BackendConnector';
import InputField from '../components/InputField';
import CenteredLayout from '../components/layout/CenteredLayout';
import { Helper } from '../lib/classes/Helper';
import Breadcrumbs from '../components/Breadcrumbs';
interface Notification {
  colorString: string;
  message: string;
}

function RegisterPage() {
  const backend = new BackendConnector();
  const [userName, setUserName] = useState('');
  const [mailAdress, setMailAdress] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [responseMessage, setResponseMessage] = useState<Notification>();

  const onSubmit = () => {
    const passwordsMatching = password === passwordConfirmation;
    if(!passwordsMatching) {
      setResponseMessage({
        colorString: 'is-danger',
        message: 'Die eingegebenen Passwörter sind nicht gleich.'
      });
      return;
    }
    // ! todo: Komplexitätsprüfung der Passwörter
    const allLengthOK = Helper.validateLength(userName, 3) && Helper.validateLength(password, 12) && Helper.validateLength(mailAdress, 6);
    if(!allLengthOK) {
        setResponseMessage({
            colorString: 'is-danger',
            message: 'Aus Spamschutzgründen müssen die Felder "Benutzername" mindestens 3, "Passwort" mindestens 12 und "E-Mail Adresse" mindestens 6 Zeichen lang sein.'
        });
        return;
    }
    const mailOK = Helper.validateMail(mailAdress);
    if(!mailOK) {
        setResponseMessage({
            colorString: 'is-danger',
            message: 'Die eingegebene E-Mail Adresse ist nicht korrekt.'
        });   
        return;
    }
    backend.sendRequest('/user/register', {
      method: 'POST',
      body: {
          userName,
          userMail: mailAdress,
          userPassword: password,
      },
      callback: (r) => {
          if(r.header.success) {
            setResponseMessage({
                colorString: 'is-success',
                message: 'Dein Account wurde registriert. Dir wurde eine Mail zur Bestätigung gesendet. Klicke auf den Link in der Mail um dein Konto zu verifizieren.'
            }); 
          } else {
            setResponseMessage({
              colorString: 'is-danger',
              message: r.header.message
            })
          }
      }
  })
  }

  return (
    <CenteredLayout>
      <Breadcrumbs breadcrumbLevels={[
        {
          name: 'Start',
          relativeURL: '/'
        },
        {
          name: 'Registrierung',
          relativeURL: '/register'
        }
      ]}></Breadcrumbs>
        <h1 className="title is-1">Registrierung</h1>
        {responseMessage ? <div className={'notification ' + responseMessage.colorString}>{responseMessage.message}</div> : ''}
        <InputField
          displayName='Benutzername'
          inputType='text'
          placeHolder='Benutzername'
          onChangeHandler={(ev) => setUserName(ev.target.value)}
          icon={faUser}
        />
        <InputField
          displayName='E-Mail Adresse'
          inputType='email'
          placeHolder='deine@mail.tld'
          onChangeHandler={(ev) => setMailAdress(ev.target.value)}
          icon={faEnvelope}
        />
        <InputField
          displayName='Passwort'
          inputType='password'
          onChangeHandler={(ev) => setPassword(ev.target.value)}
          icon={faKey}
        />
        <InputField
          displayName='Passwort bestätigen'
          inputType='password'
          onChangeHandler={(ev) => setPasswordConfirmation(ev.target.value)}
          icon={faKey}
        />
        <div className="buttons">
          <button className="button is-primary" onClick={onSubmit}>Login</button>
        </div>
    </CenteredLayout>
  );
}

export default RegisterPage;
