import { redirect } from "react-router-dom";

interface IRequestParams {
    method: 'POST' | 'GET' | 'PUT' | 'DELETE';
    body?: object;
    callback: (response: any) => void;
}

export class BackendConnector {
    DEFAULT_API_BASE = 'https://api.janfath.de'
    DEFAULT_API_VERSION = 'v1';
    APP_KEY = 'j481jnfjo912ij3nfii912030okmdnahjui89okjmnjai938'

    constructor() {
        if(process.env.NODE_ENV === 'development') {
            console.log('Backend Connection in DEVELOPMENT mode.');
            this.DEFAULT_API_BASE = 'http://localhost:8000'
        }
    }

    hasUserToken() {
        const foundToken = localStorage.getItem('userToken');
        if(foundToken) {
            return true;
        } else {
            return false;
        }
    }

    getUserToken() {
        const foundToken = localStorage.getItem('userToken');
        if(foundToken) {
            return foundToken;
        } else {
            return '';
        }
    }

    sendRequest(path: string, requestParams: IRequestParams) {
        // ! todo: User Token an Header anhängen
        let headers: HeadersInit = {
            'Content-Type': 'application/json',
            'X-CALLER-APP': 'jand0t-website',
            'X-APPKEY': this.APP_KEY
        };
        if(this.hasUserToken()) {
            headers['X-USER-TOKEN'] = this.getUserToken();
        }
        fetch(`${this.DEFAULT_API_BASE}/${this.DEFAULT_API_VERSION}${path}`, {
            method: requestParams.method,
            headers,
            body: JSON.stringify(requestParams.body)
        })
        .then(response => response.json())
        .then(data => {
            if(!data.header.success) {
                console.log('Backend rückmeldung... success = false');
                if(data.header.message === 'AUTH ERROR') {
                    console.log('AUTH ERROR!!!');
                    localStorage.removeItem('userToken');
                    redirect('/login?info=Sitzung abgelaufen. Bitte erneut anmelden.');
                    // todo laaft so gar nid... was machen sachen... vielleicht nur auf cookie umstellen?
                    
                }
            }
            requestParams.callback(data)
        });
    }
}