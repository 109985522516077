export class Helper {
    
    static validateNotEmpty(toValidate: string) {
        if(toValidate === '') {
            return false;
        } else {
            return true;
        }
    }

    static validateMail(toValidate: string) {
        if(Helper.validateNotEmpty(toValidate)) {
            // eslint-disable-next-line
            if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(toValidate)) {
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    static validateLength(toValidate: string, minLength: number) {
        if(toValidate.length >= minLength) {
            return true;
        } else {
            return false;
        }
    }
}