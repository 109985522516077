import React from 'react';
import 'bulma/css/bulma.min.css';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

interface MainLayoutProps {
    children?: React.ReactNode;
    pageTitle?: string;
}

function MainLayout(props: MainLayoutProps) {
    
    return (
        <div className='layout-main'>
            <Helmet>
                <html className='has-navbar-fixed-top'/>
                <title>Jan Fath {props.pageTitle ? '| ' + props.pageTitle : ''}</title>
            </Helmet>
            <Header />
            <div className="container is-fluid my-6">
                {props.children}
            </div>
            <Footer />
            <div 
                className="notification-area"
                style={{ position: 'fixed', right: '10px', bottom: '10px', minWidth: '50px', maxWidth: '50%'}}
            >
            </div>
        </div>
    );
}

export default MainLayout;