import React from 'react';
import 'bulma/css/bulma.min.css';
import Header from '../components/layout/Header';

function NotFound() {
    return (
        <div className="container is-fluid">
            <Header />
            <div className="box">
                <h1 className="title is-1">Not Found!</h1>
            </div>
        </div>
    );
}

export default NotFound;
