import 'bulma/css/bulma.min.css';
import EmptyLayout from '../components/layout/EmptyLayout';
import { faArrowsRotate, faCode, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactForm from '../components/ContactForm';

function StartPage() {
  return (
    <EmptyLayout>
      <section className="hero is-primary is-large">
        <div className="hero-body">
          <div>
            <div className="title">Jan Fath</div>
            <div className="subtitle">Webentwickler, Linux-Administrator, DevOps-Spezialist</div>
          </div>
        </div>  
      </section>
      <section className='section is-medium'>
          <div className="container">
            <h1 className="title is-3 has-text-centered">Top-Kenntnisse</h1>
            <div className="columns mt-3">
              <div className="column has-text-centered">
                <span className="icon">
                    <FontAwesomeIcon icon={faServer} size='3x'></FontAwesomeIcon>
                </span>
                <h4 className="title is-4">Linux-Server Administration</h4>
                <p className="block">Im Rahmen meiner Ausbildung und meiner Festanstellung habe ich diverse Kenntnisse in der Installation, Verwaltung und Pflege von Linux-Server-Systemen unterschiedlicher Art aufgebaut.</p>
              </div>
              <div className="column has-text-centered">
                <span className="icon">
                    <FontAwesomeIcon icon={faCode} size='3x'></FontAwesomeIcon>
                </span>
                <h4 className="title is-4">Webentwicklung</h4>
                <p className="block">Durch meinen ursprünglichen Einstieg als Webentwickler habe ich auch hier fundierte Kenntnisse aufgebaut und kann durch meine Kenntnisse im DevOps-Bereich auch mit Kenntnissen im Bereich CI/CD sowie der Bereitstellung glänzen.</p>
              </div>
              <div className="column has-text-centered">
              <span className="icon">
                    <FontAwesomeIcon icon={faArrowsRotate} size='3x'></FontAwesomeIcon>
                </span>
                <h4 className="title is-4">Automatisierung</h4>
                <p className="block">Meine Kenntnisse im automatisieren unterschiedlicher Prozesse, egal ob es sich dabei um simple Tasks oder komplexe Schnittstellenaufgaben zwischen unterschiedlichen Systemen handelt, habe ich im Rahmen meiner langjährigen Erfahrung regelmäßig unter Beweis gestellt.</p>
              </div>
            </div>  
          </div>
      </section>
      <section className="hero is-primary">
        <div className="hero-body">
          <div>
            
          </div>
        </div>  
      </section>
      <section className="section">
        <div className="container">
          <h1 className="title is-3 has-text-centered">Zahlen</h1>
          <nav className="level">
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Erfahrung</p>
                <p className="title">10 Jahre</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Repositories</p>
                <p className="title">23</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Zertifikate</p>
                <p className="title">0</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Skills</p>
                <p className="title">3,123</p>
              </div>
            </div>
          </nav>
        </div>
      </section>
      <section className="hero is-primary">
        <div className="hero-body">
          <div>
            
          </div>
        </div>  
      </section>
      <section className="section">
        <div className="container">
          <h1 className="title is-3 has-text-centered">Kontakt</h1>
          <div className="columns">
            <div className="column">
              <ContactForm />
            </div>
            <div className="column">
              <p className="block has-text-right">Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae, vitae dolores. Repellendus perferendis perspiciatis illum sequi laborum omnis porro corrupti praesentium, magnam dolorem et inventore error? Porro est at nulla.</p>
            </div>
          </div>
        </div>
      </section>
    </EmptyLayout>
  );
}

export default StartPage;
