import 'bulma/css/bulma.min.css';
import EmptyLayout from '../components/layout/EmptyLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaystation } from '@fortawesome/free-brands-svg-icons';

function CVPage() {
  return (
    <EmptyLayout>
      <main>
        <div className="container">
          <div className="box" style={{position: 'absolute', maxWidth: 120}}>
            <span className="icon has-text-primary has-text-centered is-large">
              <FontAwesomeIcon icon={faPlaystation} size='2x'/>
            </span>
          </div>
        </div>
      </main>
    </EmptyLayout>
  );
}

export default CVPage;
