import React from 'react'; 
import 'bulma/css/bulma.min.css';
import MainLayout from './MainLayout';

interface CenteredLayoutProps {
    children?: React.ReactNode;
    pageTitle?: string;
}

function CenteredLayout(props: CenteredLayoutProps) {
    
    return (
        <MainLayout pageTitle={props.pageTitle}>
            <div className="columns">
                <div className="column is-4">
                </div>
                <div className="column">{props.children}</div>
                <div className="column is-4"></div>
            </div>
        </MainLayout>
    );
}

export default CenteredLayout;