import React from 'react';
import 'bulma/css/bulma.min.css';

interface Breadcrumb {
    name: string;
    relativeURL: string;
}

interface BreadcrumbProps {
    breadcrumbLevels: Breadcrumb[];
}

function Breadcrumbs(props: BreadcrumbProps) {
    return (
        <nav className="breadcrumb is-left" aria-label='breadcrumbs'>
            <ul>
                {props.breadcrumbLevels.map(level => {
                    return (
                        <li>
                            <a href={level.relativeURL}>{level.name}</a>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}

export default Breadcrumbs;