import React from 'react';
import 'bulma/css/bulma.min.css';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InputFieldProps {
    displayName: string;
    icon?: IconDefinition;
    inputType: React.HTMLInputTypeAttribute;
    placeHolder?: string;
    onChangeHandler: React.ChangeEventHandler<HTMLInputElement>;
}

function InputField(props: InputFieldProps) {
    return (
        <div className="field">
          <label className="label">{props.displayName}</label>
          <div className={props.icon ? 'control has-icons-left' : 'control'}>
            <input 
              type={props.inputType} 
              className="input" 
              placeholder={props.placeHolder}
              onChange={props.onChangeHandler}
            />
            {props.icon? 
                <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
                </span>
            :
                ''
            }
          </div>
        </div>
    );
}

export default InputField;